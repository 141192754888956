import Next from '../allSVGS/Next.svg';
import  Back from '../allSVGS/Back.svg';
import  Cancel from '../allSVGS/Cancel.svg';
import  Login from '../allSVGS/Login.svg';
import  Skip from '../allSVGS/Skip.svg';
import  Submit  from '../allSVGS/Submit.svg';
import Arabicnext from '../allSVGS/Arabic SVGS/ArabicNext .svg';
import Arabicback from '../allSVGS/Arabic SVGS/ArabicBack.svg';
import Arabiccancel from '../allSVGS/Arabic SVGS/ArabicCancel.svg';
import Arabiclogin from '../allSVGS/Arabic SVGS/ArabicLogin.svg';
import Arabicskip from '../allSVGS/Arabic SVGS/ArabicSkip.svg';
import Arabicsubmit from '../allSVGS/Arabic SVGS/ArabicSubmit.svg';


export default function Localstorage(){
let selectedlanguage=localStorage.getItem('selectedlanguage');
let selecteddepartment = JSON.parse(localStorage.getItem('selecteddepartment'));
let data;

let content = {
        English:{
              Details:"Share Your Details",
              Department:selecteddepartment.department_english,
              FullName:"Full Name  ", 
              ContactNumber:"Contact Number:",
              EmailId:"Email ID ",
              optional:"Optional",
              Complaintform:"COMPLAINT FORM",
              complaint:"Do you have any complaints regarding our services (Optional)?",
              complaintraise:"Raise a complaint",
              complaintarea:"Text here....",
              thanks:"THANK YOU FOR YOUR",
              feedback:"VALUABLE FEEDBACK",
              empty:"Field cannot be empty",
              field:"Accept Alphabets Only",
              number:"Invalid Number",
              email:"Email format only Accepted",
              name:"Only Alphabets and Spaces Allowed",
              sad:'Sad',
              fair:'Neutral',
              happy:'Happy',
              unlikely:'Unlikely',
              likely:'Likely',
              verylikely:'Very Likely',
              next:<img src={Next} alt="next" className='back_img'/>,
              next1:<img src={Next} alt="next" className='mb-2'/>,
              back:<img src={Back} alt="next" className='back_img'/>,
              cancel:<img src={Cancel} alt="next" className='back_img'/>,
              login:<img src={Login} alt="next" className='back_img'/>,
              skip:<img src={Skip} alt="next" className='back_img'/>,
              submit:<img src={Submit} alt="next" className='back_img'/>,
              submit1:<img src={Submit} alt="next"/>

            },
         Arabic:{
              Details:"مشاركة التفاصيل الخاصة بك",
              Department:selecteddepartment.department_arabic,
              FullName:"الاسم الكامل", 
              empty:"لا يمكن أن يكون الحقل فارغًا",
              ContactNumber:"رقم التواصل",
              EmailId:"عنوان الايميل",
              optional: "اختياري",
              Complaintform:"نموذج الشكوى",
              complaint:"هل لديك أي شكاوى بخصوص خدماتنا (اختياري)؟",
              complaintraise:"رفع شكوى",
              complaintarea:"أكتب هنا.....",
              thanks:"شكرا لك على الخاص بك",
              feedback:"ردود فعل قيمة",
              field:"قبول الحروف الهجائية فقط",
              number:"رقم غير صالح",
              email:"تنسيق البريد الإلكتروني مقبول فقط",
              name:"يسمح فقط بالحروف الأبجدية والمسافات",
              sad:'حزين',
              fair:'معرض',
              happy:'سعيدة',
              unlikely:'من غير المرجح',
              likely:'محتمل',
              verylikely:'من المرجح جدا',
              next:<img src={Arabicnext} alt="next"  className='back_img'/>,
              next1:<img src={Arabicnext} alt="next" />,
              back:<img src={Arabicback} alt="next"  className='back_img'/>,
              cancel:<img src={Arabiccancel} alt="next" className='back_img'/>,
              login:<img src={Arabiclogin} alt="next" className='back_img'/>,
              skip:<img src={Arabicskip} alt="next" />,
              submit:<img src={Arabicsubmit} alt="next" className='back_img'/>,
              submit1:<img src={Arabicsubmit} alt="next"/>    
        }
    }

    if(selectedlanguage==="English")
    {
        data = content.English;
    }
    else{
        data = content.Arabic;
    }

return data;
}

