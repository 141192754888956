import axios from "axios";

export default function Answersposting(Answers) {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const owner = JSON.parse(localStorage.getItem("ownerDetails"));
  const questionsData = JSON.parse(localStorage.getItem("questionsData"));

  let DiD = localStorage.getItem("department_id");
  if (DiD == null) {
    DiD = 1;
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}api/questionandanswers`,
    headers: {
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    data: {
      user_name: user.name,
      email: user.email,
      phone_number: user.number,
      qaData: questionsData,
      dept_id: owner.departmentId,
      admin_id: owner.adminId,
      branch_id: owner.branchId,
    },
  })
    .then((response) => {
      if (response.status) {
        return response;
      } else {
      }
    })
    .catch((err) => {
      if (err.message == "Request failed with status code 404") {
        localStorage.clear();
        alert("Department is Deleted");
        navigator(
          `/home/?adminId=${owner.adminId}&branchId=${owner.branchId}&departmentId=${owner.departmentId}&lats=${owner.lats}&long=${owner.long}`
        );
      } else {
        alert(err.response.data.details[0].message);
      }
    });

  // axios.post(`${process.env.REACT_APP_BASE_URL}/api/questionsandanswers`,
  //                          {user_name:user.name,
  //                           email:user.email,
  //                           phone_number:user.number,
  //                           qObj:questionsData,
  //                           dept_id:owner.departmentId,
  //                           admin_id:owner.adminId,
  //                           branch_id:owner.branchId
  //                         },
  //                          {headers:{ Authorization:process.env.REACT_APP_AUTH_KEY}}
  //                           )
  //        .then(res=>{
  //             return res;})
}
