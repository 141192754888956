import React, { useState } from "react";
import cancel from "../../allSVGS/Cancel.svg";
import Cancel from "../../allSVGS/cancel.svg";
import submit from "../../allSVGS/submit.svg";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { toastMsg } from "../../../../helper";
import "../Logging/login.css";
import Recaptcha from "./recaptcha";

export default function SuperAdminResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const [passwordType, setPasswordType] = useState("password");
  const [pwType, setPwType] = useState("password");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const params = useParams();
  const email = location.pathname.split("/")[2];
  const token = location.pathname.split("/")[3];
  const [captcha, setCaptcha] = useState("");
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");

  const handleShowPass = () => {
    let pwd = document.getElementById("passwordField");
    if (pwd.type === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const handlePass = () => {
    let pwd1 = document.getElementById("passwordField1");
    if (pwd1.type === "password") {
      setPwType("text");
    } else {
      setPwType("password");
    }
  };

  function onChange(value) {
    let captchaval = value;
    setCaptcha(captchaval);
    setErr(false);
    return captchaval;
  }

  const handlesave = (data) => {
    if (data.newPassword == data.SuperAdminReset) {
      let fetch = async () => {
        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/api/adminresetpass/${email}/${token}`,
            { password: data.newPassword },
            {
              headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
            }
          )
          .then((res) => {
            console.log(res, "checking response");

            if (res.data.status == "sucess") {
              toastMsg("Reset password link sent successfully, Please check your mail", toast.POSITION.TOP_CENTER, 2000);
              setTimeout(() => {
                navigate("/");
              }, 2000);
            }
          });
      };
      if (captcha) {
        fetch();
      } else {
        setErr(true);
      }
    } else {
      setMsg("newPassword and confirmPassword must be same");
    }
  };
  return (
    <>
      <ToastContainer closeButton={false} hideProgressBar={true} />
      <div className=" userdetails container-fluid">
        <div className="row  h-100">
          <div className="offset-lg-3 col-lg-6  ResetAdject">
            <div className="card_respo  h-100  d-flex align-items-center ">
              <div className="card card1">
                <div className="card card2">
                  <div className="card card3">
                    <div className=" mt-4 text-center">
                      <h4 className="lgin">Admin Password Change</h4>
                    </div>
                    <br />
                    <div className="body">
                      <form className="forms" autoComplete="off">
                        <div className="row offset-md-1 align-items-center">
                          <div className="col-md-4">
                            <label className="lable_title">New Password</label>
                          </div>
                          <div className="col-md-7 pass_input">
                            <input id="passwordField" className="brdr_clr" type={passwordType} {...register("newPassword", { required: true })} />
                            <span type="button" onClick={handleShowPass}>
                              {" "}
                              {passwordType == "password" ? <FaEyeSlash className="fa_eye" /> : <FaEye className="fa_eye" />}
                            </span>
                            {errors.newPassword && errors.newPassword.type === "required" && <p className="error">Field cannot be empty</p>}
                          </div>
                        </div>
                        <br></br>
                        <div className="row offset-md-1 align-items-center">
                          <div className="col-md-4">
                            <label className="lable_title">Confirm Password</label>
                          </div>
                          <div className="col-md-7 pass_input">
                            <input className="brdr_clr" id="passwordField1" type={pwType} {...register("SuperAdminReset", { required: true })} />
                            <span type="button" onClick={handlePass}>
                              {" "}
                              {pwType === "password" ? <FaEyeSlash className="fa_eye" /> : <FaEye className="fa_eye" />}
                            </span>
                            {errors.SuperAdminReset && errors.SuperAdminReset.type === "required" && <p className="error">Field cannot be empty</p>}
                            <p className="error">{msg} </p>
                          </div>
                        </div>
                        <div className="ml-5 d-flex justify-content-center">
                          <Recaptcha onChange={onChange}></Recaptcha>
                        </div>
                        <p className={err ? "errormessage d-flex justify-content-center ml-5" : "errormessage1 "}>Please complete the reCAPTCHA. Verify that you are not a robot.</p>
                      </form>
                      <div className="row pt-4 pb-4">
                        <div className=" col-6 text-center">
                          <img src={Cancel} alt="cancel" onClick={() => navigate("/")} />
                        </div>
                        <div className=" col-6 text-center">
                          <img src={submit} alt="submit" onClick={handleSubmit(handlesave)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
