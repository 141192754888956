import React, { useState } from "react";
import image from "../allSVGS/image.gif";
// import data from  '../utils/Localstorage';
import Cancel from "../allSVGS/Cancel.svg";
import Submit from "../allSVGS/Submit.svg";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./selection.css";

function Admindepartmentselection() {
  const [department, setDepartment] = useState([]);
  const [check, setCheck] = useState(false);
  const [did, setDid] = useState();
  const [msg, setMsg] = useState("");
  let selecteddepartment = JSON.parse(
    localStorage.getItem("selecteddepartment")
  );
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/departments`, {
        headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        setDepartment(res.data.data);
      });
  }, []);

  const handleChange = (e) => {
    setDid(e.target.value);
    setCheck(true);
  };

  const Cancel1 = () => {
    localStorage.removeItem("restricted");
    navigate("/language");
  };

  const Submit1 = (e) => {
    if (check) {
      localStorage.setItem("department_id", did);
      navigate("/language");
    } else {
      setMsg("please select department");
    }
  };

  return (
    <div className=" userdetails container-fluid">
      <div className="row">
        <div className="col-md-2">
          <img src={image} alt="department" className="image_size" />
        </div>
        <div className="col-md-8 department2">
          <h3>{selecteddepartment.department_english} </h3>
        </div>
      </div>
      <div className="  offset-lg-3 col-lg-6">
        <div className="card_respo  select_card">
          <div className="card card1">
            <div className="card card2">
              <div className="card card3">
                <div className="header mt-4">
                  <h4 className="text-center">Change Department</h4>
                </div>
                <div className="body text-center mt-5">
                  <form>
                    <div className="row ">
                      <div className="col-md-4">
                        <label>Department</label>
                      </div>
                      <div className="col-md-7">
                        <select className="brdr_clr" onChange={handleChange}>
                          <option disabled selected>
                            Select Required Department
                          </option>
                          {department.map((value, key) => (
                            <option key={key} value={value.d_id}>
                              {value.department_english}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p class="error">{msg}</p>
                    </div>
                    <br />
                    <br />
                  </form>
                </div>
                <br />
                <div className="row footer mt-0 pb-4">
                  <div className="col-sm-6 col-6" onClick={Cancel1}>
                    <img src={Cancel} alt="Back" className="back_img" />
                  </div>
                  <div className="col-sm-6 col-6" onClick={Submit1}>
                    <img src={Submit} alt="Next" className="back_img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admindepartmentselection;
