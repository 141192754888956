// import React from "react";
// import { Navigate } from "react-router-dom";
// import { isLogin } from "../../../src/superhelper";


// const SuperPrivateRoute = ({ component: Component, ...rest }) => {
//   if (isLogin) {
//     return <Component {...rest} />;
//   } else {
//     return <Navigate to="/" />;
//   }
// };
// export default SuperPrivateRoute;

import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { isLogin } from '../../../src/superhelper';

const SuperPrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("TOKEN_KEY");

  if (token && isLogin()) {
    return <Component {...rest} />;
  } else {
    return <Navigate to="/" />;
  }
};

export default SuperPrivateRoute;
