import React, { useEffect, useState } from "react";
import Localstorage from "../utils/Localstorage";
import Thanks from "../allSVGS/Thankyou.gif";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Reset } from "../../Redux/Actioncreator";
import "./thankyou.css";
import Answersposting from "../../APIS/Answersposting";

function Thankyou() {
  let data = Localstorage();
  let Answers = useSelector((state) => state);
  let navigator = useNavigate();
  let dispatch = useDispatch();
  // const [confirm, setConfirm] = useState(true);

  const check = async () => {
    localStorage.removeItem("questionsData");
    localStorage.removeItem("userDetails");
    // let response = await Answersposting();
    // if (response) {
    //   setConfirm(true);
    //   localStorage.removeItem("questionsData");
    //   localStorage.removeItem("userDetails");
    // } else {
    //   setConfirm(false);
    //   localStorage.removeItem("questionsData");
    //   localStorage.removeItem("userDetails");
    // }
  };

  let Details = JSON.parse(localStorage.getItem("ownerDetails"));
  
  useEffect(() => {
    check();
  }, []);

  setTimeout(() => {
    navigator(`/home/?adminId=${Details.adminId}&branchId=${Details.branchId}&departmentId=${Details.departmentId}&lats=${Details.lats}&long=${Details.long}`)
  }, 3000);

  return (
    <>
      <div className="row thanks container-fluid">
        <div className="offset-lg-1 col-lg-10">
          <div className="offset-lg-5 mt-4">
            <h3 className="header"> {data.Department} </h3>
          </div>
          <div className="card_respo  surface_cardtop">
            <div className="card surface_card card1">
              <div className="card surface_card card2">
                <div className="card surface_card card3">
                  <div className="body position_page m-4">
                    <img src={Thanks} alt="thankyou " className="image-fluid gif surface_image" />
                  </div>

                  <div className="row thank_footer footer pb-4">
                    <p>
                      {data.thanks}
                      <br></br>
                      {data.feedback}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Thankyou;
