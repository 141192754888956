import React, { component, Suspense } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter,
  Router,
} from "react-router-dom";
import Userdetails from "./userdetails/Userdetails";
import Language from "./languagePage/Language";
import Questionscreen from "./firstQuestionScreen/Questionscreencopy";
import Questionscreen2 from "./secondQuestionScreen/Questionscreen2";
import Complaint from "./complaint/Complaint";
// import Googlereview from './googlereviews/Googlereview';
import Thankyou from "./thankyou/Thankyou";
import Adminlogin from "./adminlogin/Adminlogin";
import Admindepartmentselection from "./adminDepartmentSelection/Admindepartmentselection";
import Privateroute from "./utils/Privateroute";
import Home from "./homescreen/homescreen";
import SuperAdminResetPassword from "./SuperAdmin/components/Logging/superResetPass";
import SuperAdminLogin from "./SuperAdmin/components/Logging/superAdminLog";
import SuperAdminForgorPassword from "./SuperAdmin/components/Logging/superForgotPass";
import Superdashboard from "./SuperAdmin/components/Dashboard/dashboard";
import SuperHospital from "./SuperAdmin/components/Hospitals/Hospital";
import SuperAdminMain from "./SuperAdmin/components/layout/layout";
import Questionscreencheck from "./firstQuestionScreen/Questionscreencheck";
import QuestionscreenLatest from "./firstQuestionScreen/QuestionscreencheckLatest";
import SuperPrivateRoute from "./utils/superprivateroute";
import SuperPublicRoute from "./utils/superpublicroute";

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        {/* User Routings */}

        <Route path="/home" element={<Home />} />
        <Route exact path="/language" element={<Language />} />
        <Route exact path="/user" element={<Userdetails />} />
        {/* <Route exact path='/question1' element={<Questionscreencheck />} /> */}
        <Route exact path="/question1" element={<QuestionscreenLatest />} />
        {/* <Route exact path='/question1' element={<Questionscreen />} /> */}
        <Route exact path="/question2" element={<Questionscreen2 />} />
        <Route exact path="/complaint" element={<Complaint />} />
        {/* <Route exact path='/review' element={<Googlereview />} /> */}
        <Route exact path="/thankyou" element={<Thankyou />} />
        <Route exact path="/adminlogin" element={<Adminlogin />} />
        <Route
          exact
          path="/admindepartmentselection"
          element={
            <Privateroute>
              <Admindepartmentselection />
            </Privateroute>
          }
        />
        <Route
          path="/"
          element={<SuperPublicRoute component={SuperAdminLogin} />}
        />
        <Route
          path="/SuperAdminForgot"
          element={<SuperPublicRoute component={SuperAdminForgorPassword} />}
        />
        <Route
          path="/SuperAdminReset/:id/:id"
          element={<SuperPublicRoute component={SuperAdminResetPassword} />}
        />
        <Route
          path="/SuperAdminMain"
          element={<SuperPrivateRoute component={SuperAdminMain} />}
        >
          <Route path="Superdashboard" element={<Superdashboard />} />
          <Route path="SuperHospital" element={<SuperHospital />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
