import React, { useState } from 'react';
import image from '../../allSVGS/image.gif';
import Cancel from '../../allSVGS/Cancel.svg';
import Login from '../../allSVGS/Login.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Logging/login.css';
import { useForm } from 'react-hook-form';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { login } from '../../../../superhelper';
import { Link } from 'react-router-dom';

function SuperAdminLogin() {
  const [passwordType, setPasswordType] = useState('password');
  const { register, handleSubmit, formState: { errors } } = useForm();

  let [data, setData] = useState({ Username: '', Password: '' })
  let [message, setMessage] = useState('')

  let navigator = useNavigate()



  const onSubmit = async (data) => {
    await axios.post(`${process.env.REACT_APP_BASE_URL}api/superadminlogin`, { email: data.Username, password: data.Password }, {
      headers: { Authorization: process.env.REACT_APP_AUTH_KEY }
    })
      .then(res => {
        if (res.data.status === 'success') {
          console.log(res.data);
          localStorage.setItem('restricted', true)
          login();
          navigator('/SuperAdminMain/Superdashboard',{replace:true});
        }
        else {
          setMessage(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
      })

  }
  // const handleback = () => {
  //   navigator('/language');
  // }

  const handleShowPass = () => {
    //  console.log('click')
    let pwd = document.getElementById('passwordField');
    if (pwd.type === 'password') {
      setPasswordType('text')
    } else {
      setPasswordType('password')

    }
  }

  const check = () =>{
    navigator('/SuperAdminForgot');
  }


  return (
    <div className=" userdetails container-fluid">
      <div className='row h-100 d-flex align-items-center'>
        <div className='offset-lg-3 col-lg-6'>

          <div className="card_respo d-flex align-items-center">
            <div className='card card1'>
              <div className='card card2'>
                <div className='card card3'>
                  <div className=" mt-4 text-center"><h4 className='lgin-header'>Super Admin Login</h4>
                  </div><br />
                  <div className="body">
                    <form autoComplete='off'>
                      <div className='row offset-md-1'>
                        <div className=' lable_title col-md-3'>
                          <label >Username</label>
                        </div>
                        <div className='col-md-7'>
                          <input className='brdr_clr' type="text" {...register('Username', { required: true, pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ })} />
                          {errors.Username && errors.Username.type === "required" && <p className='error mt-0'>Required field</p>}
                          {errors.Username && errors.Username.type === "pattern" && <p className='error mt-0'>please enter valid Email</p>}
                          {/* <p className='error'><small>{message} </small></p> */}
                        </div>


                      </div><br></br>
                      <div className='row offset-md-1'>
                        <div className=' lable_title col-md-3'>
                          <label >Password</label>
                        </div>
                        <div className='col-md-7 pass_input'>
                          <input id='passwordField' className='brdr_clr' type={passwordType} {...register('Password', { required: true })} />
                          <span type='button' onClick={handleShowPass} > {passwordType === 'password' ? <FaEyeSlash className='fa_eye' /> : <FaEye className='fa_eye' />}</span>
                          {errors.Password && errors.Password.type === "required" && <p className='error mt-0'>Required field</p>}

                        </div>
                      </div>
                      <div className='row offset-md-1'>
                        <div className='col-md-3'>

                        </div>

                        <div className='col-md-7 mt-2'>
                        <Link to="/SuperAdminForgot" className="forgot_label">
                            Forgot password?
                          </Link>
                          {/* <p onClick={check} style={{cursor:'pointer'}}>Forgot Password?</p> */}
                            <p className='error'>{message}</p>
                        </div><br />
                      </div>

                    </form>

                  </div>

                  <br />
                  <div className="row footer pb-4">
                    <div className='col-sm-6 col-6'></div> 
                    {/* <div className='col-sm-6 col-6'><img className='back_img' src={Cancel} alt="Back" onClick={handleback} /></div> */}
                    <div className='col-sm-6 col-6'><img className='back_img' src={Login} alt="Next" onClick={handleSubmit(onSubmit)} /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuperAdminLogin
