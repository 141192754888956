import React,{useState} from 'react';
import image from  '../allSVGS/image.gif';
import Cancel from '../allSVGS/Cancel.svg';
import Login from '../allSVGS/Login.svg';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import './adminlogin.css';
import { useForm } from 'react-hook-form';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

function Adminlogin(props) {
const [passwordType,setPasswordType]=useState('password');
  const {register,handleSubmit,formState: { errors }} = useForm();

  let [data,setData] = useState({Username:'',Password:''})
   let [message,setMessage] = useState('')

  let navigator=useNavigate()
  let selecteddepartment = JSON.parse(localStorage.getItem('selecteddepartment'));
 

 const onChangeHandler = (e) =>{
    const {name,value} = e.target;
   
    setData({...data,[name]: value});
    
  }
  
 const onSubmit=(data)=>{
     
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/login`,{email:data.Username,password:data.Password},{
                          headers:{Authorization:process.env.REACT_APP_AUTH_KEY}
                            })
        .then(res => {
             
            
          if(res.data.status==='success')
            {
              localStorage.setItem('restricted',true);
             navigator('/admindepartmentselection');
           }
           else
           {
            setMessage(res.data.mesage)
            setTimeout(() => {
              setMessage('');
            }, 2000);
           }
          
           
        })
    }     
 

    const handleback=()=>{
    navigator('/language');
    }

//  console.log(message,'fkjb')
 const handleShowPass=()=>{
  //  console.log('click')
   let pwd = document.getElementById('passwordField');
   if(pwd.type==='password'){
    setPasswordType('text')
   }else{
    setPasswordType('password')

   }
 }
  return (
    <div className=" userdetails container-fluid"> 
    <div className='row'>
      {/*<div className= 'col-md-3'><img src={image} alt="department" className="img_size" /></div>*/}
      <div className= 'col-md-3'></div>
      <div className= 'col-md-6 department2'><h3 className='deprt_brdr'>{selecteddepartment.department_english}</h3></div>
      </div>
       <div className='offset-lg-3 col-lg-6'>          
           <div className="card_respo login_responsive ">
            <div className='card card1'>
              <div className='card card2'>
                <div className='card card3'>
                    <div className="header mt-4 text-center"><h4>Admin Login</h4>
                      </div><br/>
                          <div className="body">
                                <form autoComplete='off'>
                                  <div className='row offset-md-1'>
                                    
                                      <div className='col-md-3'>
                                   <label >Username</label>
                                   </div>
                                   <div className='col-md-7'>
                                   <input className='brdr_clr' type="text" {...register('Username',{required: true,pattern:/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/})} />
                                   {errors.Username && errors.Username.type === "required" && <p className='error mt-0'>Field cannot be Empty</p>}
                                   {errors.Username && errors.Username.type === "pattern"  && <p className='error mt-0'>please enter valid Email</p>}
                                   </div>
                                  
                                   </div><br></br>
                                   <div className='row offset-md-1'>
                                     <div className='col-md-3'>
                                   <label >Password</label> 
                                   </div>
                                   <div className='col-md-7 pass_input'>
                                   <input id='passwordField' className='brdr_clr' type={passwordType} {...register('Password',{required: true})} />
                                   <span type='button' onClick={handleShowPass} > {passwordType ==='password'?  <FaEyeSlash className='fa_eye' /> :<FaEye className='fa_eye'/>}</span>                                   
                                   
                                   
                                   {errors.Password && errors.Password.type === "required" && <p className='error mt-0'>Field cannot be Empty</p>}
                                   <p className='error'><small>{message} </small></p>                                 

                                 
                                   </div><br/>
                                   </div>
                                 
                                </form>
                            
                            
                            </div> 
                          
                            <br/>
                            <div className="row footer pb-4">
                                <div className='col-sm-6 col-6'><img className='back_img' src = {Cancel} alt ="Back" onClick={handleback} /></div>
                                <div className='col-sm-6 col-6'><img className='back_img' src = {Login} alt ="Next" onClick={handleSubmit(onSubmit)} /></div>
                         </div>
                      </div>
                  </div>
             </div>
       </div>
    </div>
  </div>
  )
}

export default Adminlogin

