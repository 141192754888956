export const User = (first_name,email,phone_number) => {

  return {
    type: "userdetails",
    payload: {
      first_name,email,phone_number
    }
  }
}

export const Questions = (data) => {

  return {
    type: "questions",
    payload: {
      data
    }
  }
}

export const Userid = (id) => {

  return {
    type: "user_id",
    payload: {
      id
    }
  }
}

export const Reset = () => {

  return {
    type: "reset",
  
  }
}





