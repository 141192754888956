import React from 'react';
import Cancel from '../allSVGS/Cancel.svg';
import Login from '../allSVGS/Login.svg';
import {Button,Modal } from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import {useState} from 'react'
import './model.css';



function Adminloginmodal(props) {
   let navigator=useNavigate();
   let selecteddepartment = JSON.parse(localStorage.getItem('selecteddepartment'));
   
   const [show, setShow] = useState(false);
   const handleCancel =() =>{
      setShow(false)
      
   }
   
   
   const handleClose = () => {
      navigator('/adminlogin')
   };
   
   const handleShow = () => setShow(true);

  
   return (
    <div className=" admin  row"> 
    
    <div className='department_size' onClick={handleShow}><h3 className="depart"> {props.name}</h3></div>
    

     <div className='offset-lg-2 col-lg-7'>
           <Modal show={show} >
      <div className="card card1" >
                       <div className="card card2" >
                          <div className="card card3" >
                              <div className="body edit_department">
                                    <p>Please login to edit the department</p><br/>
                                    <div className = 'row pb-5'>
                                         <div className = 'col-6' data-bs-dismiss>  
                                              <img className='back_img' src={Cancel} alt="cancel" onClick ={handleCancel}/>
                                         </div>
                                         <div className = 'col-6'>
                                       <img  className='back_img' src={Login} alt="cancel"  onClick={handleClose}/>
                                         </div>
                                    </div>
                              </div>
                          </div>
                       </div>
                    </div>
            </Modal>
 </div>
  </div>

  )
}
export default Adminloginmodal