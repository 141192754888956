import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Questionsapi() {
  let navigate = useNavigate();
  const fetchQuestions = async () => {
    let Details = JSON.parse(localStorage.getItem("ownerDetails"));
    let DiD = Details.departmentId;
    let selectedlanguage = localStorage.getItem("selectedlanguage");
    if (DiD == null || DiD == "undefined") {
      DiD = 1;
    }

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/questions/${DiD}/${selectedlanguage}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      );
      let { data } = res;
      if (data.status === "success") {
        let response = data.data;
        // Handle success, possibly by setting state or performing other actions
        return response;
      }
    } catch (err) {
      if (err.message == "Request failed with status code 404") {
        localStorage.clear();
        alert("Department is Deleted");
        navigate(
          `/home/?adminId=${Details.adminId}&branchId=${Details.branchId}&departmentId=${Details.departmentId}&lats=${Details.lats}&long=${Details.long}`
        );
      }
    }
  };

  return { fetchQuestions };
}
