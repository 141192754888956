import React from 'react'



     
const defaultState={
first_name:'',
email:'',
phone_number:'',
qObj:[],
user_id:0
}

const Reducer = (state =defaultState, action) => {
    switch(action.type) {
      case "userdetails":
           return {...state,first_name: action.payload.first_name,email:action.payload.email,phone_number:action.payload.phone_number};
      case "questions":
            return {...state,qObj:action.payload.data};
      case "user_id":
            return{...state,user_id:action.payload.id};
      case "reset":
            return{defaultState}
      default:
        return defaultState
    }
  }
  
  export default Reducer