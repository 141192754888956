import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Cancel from "../../allSVGS/cancel.svg";
import Submit from "../../allSVGS/submit.svg";
import { useNavigate } from "react-router-dom";
import { toastMsg } from "../../../../helper";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Recaptcha from "./recaptcha";
// import ReCAPTCHA from 'react-google-recaptcha';

// import styled, { css } from "styled-components";
// import LoadingOverlay from "react-loading-overlay";
// const DarkBackground = styled.div`
//   display: none; 
//   position: fixed;
//   z-index: 999; 
//   left: 0;
//   top: 0;
//   width: 100%; /* Full width */
//   height: 100%; /* Full height */
//   overflow: auto; /* Enable scroll if needed */
//   background-color: rgb(0, 0, 0); /* Fallback color */
//   background-color: rgba(0, 0, 0, 0.2); /* Black w/ opacity */

//   ${props =>
//     props.disappear &&
//     css`
//       display: block; 
//     `}
// `;

const SuperAdminForgorPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [msg, setMsg] = useState("");
  let navigate = useNavigate();
  const [isallow, setIsAllow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [err, setErr] = useState(false);

  const onSubmit = (data) => {
    const fetch = async () => {
      setIsAllow(true)
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}api/forgotpass`, { email: data.forget }, { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } })
        .then((res) => {
          if (res.data.status == "success") {
            setIsAllow(false)
            toastMsg("link forward to your registered email", toast.POSITION.TOP_CENTER, 1000);
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            setMsg(res.data.mesage);
            setIsAllow(false)
          }
        }).catch((err)=>{
            alert(err.data.message)
            setIsAllow(false)
        })
    };
    if (captcha) {
      fetch();
    } else {
      setErr(true);
    }
   
  };

  function onChange(value) {
    let captchaval = value;
    setCaptcha(captchaval);
    setErr(false);
    return captchaval;
  }


  const handleCancel = () => {
    navigate("/");
  };

  return (
    <>
      {/* <DarkBackground disappear={isLoading}>
        <LoadingOverlay
          active={true}
          spinner={true}
          styles={{
            spinner: (base) => ({
              ...base,
              width: '50px',
              '& svg circle': {
                stroke: '#3498db'
              }
            })
          }}
        >
        </LoadingOverlay>
      </DarkBackground> */}
          <ToastContainer closeButton={false} hideProgressBar={true} />
          <div className=" userdetails container-fluid">
            <div className="row h-100 ">
              <div className="offset-lg-3 col-lg-6 adjest ">
                <div className="card_respo h-100 d-flex align-items-center ">
                  <div className="card card1">
                    <div className="card card2">
                      <div className="card card3">
                        <div className=" mt-4 text-center">
                          <h4 className=" text-center">Forgot Password</h4>
                        </div>
                        <br />
                        <div className="body">
                          <form className="forms" autoComplete="off">
                            <div className="row offset-md-1 align-items-center">
                              <div className="col-md-3">
                                <label className="lable_title">Email ID</label>
                              </div>
                              <div className="col-md-7">
                                <input className="brdr_clr" type="text" {...register("forget", { required: true, pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ })} />
                                {errors.forget && errors.forget.type === "required" && <p className="error mt-0">Required field</p>}
                                {errors.forget && errors.forget.type === "pattern" && <p className="error mt-0">please enter valid Email</p>}
                                <p className="error">{msg}</p>
                              </div>
                              <div className="mx-3 d-flex justify-content-center">
                          <Recaptcha onChange={onChange}></Recaptcha>
                        </div>
                        <p className={err ? "errormessage d-flex justify-content-center" : "errormessage1 "}>Please complete the reCAPTCHA. Verify that you are not a robot.</p>
                            </div>
                          </form>
                          <div className="row pt-4 pb-4">
                            <div className="col-sm-6 col-6 text-center" >
                              <img src={Cancel} onClick={handleCancel} disabled={isLoading} alt="cancel" />
                            </div>
                            <div className="col-sm-6 col-6 text-center">
                              <img src={Submit} alt="submit" disabled={isLoading}  onClick={isallow? "":handleSubmit(onSubmit)}/>
                            </div>
                            {/* <div>
                              <reCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY}/>
                            </div> */}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  );
};
export default SuperAdminForgorPassword;
