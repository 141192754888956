
// import React from 'react';
// import {Navigate} from 'react-router-dom';
// import {isLogin} from '../../../src/superhelper';

// const SuperPublicRoute=({component:Component, ...rest})=>{
//     const token = localStorage.getItem("TOKEN_KEY")
//     if( token == null && !isLogin ){
//         return <Navigate to="/SuperAdmin" />;
//     }else{
//         return <Component {...rest}/>
//     }
// }
// export default SuperPublicRoute;
// SuperPublicRoute.js



import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { isLogin } from '../../../src/superhelper';

const SuperPublicRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("TOKEN_KEY");
  if (!token || !isLogin()) {
    return <Component {...rest} />;
  } else {
    return <Navigate to="/SuperAdminMain/Superdashboard" />;
  }
};

export default SuperPublicRoute;


