import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import animationData from "../allSVGS/taphere.json";
import Lottie from "react-lottie";
import axios from "axios";
import homeScreen from "../allSVGS/homescreen.png";
import LoadingOverlay from "react-loading-overlay";
import { Spinner } from "react-bootstrap";

function Home() {
  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [dimensions, setDimensions] = useState({
    latitude: "",
    longitude: "",
  });

  const [searchParams] = useSearchParams();
  const adminId = searchParams.get("adminId");
  const branchId = searchParams.get("branchId");
  const departmentId = searchParams.get("departmentId");
  const lats = searchParams.get("lats");
  const long = searchParams.get("long");

  let navigating = useNavigate();

  localStorage.setItem(
    "ownerDetails",
    JSON.stringify({
      adminId: adminId,
      branchId: branchId,
      departmentId: departmentId,
      lats: lats,
      long: long,
    })
  );

  let Details = JSON.parse(localStorage.getItem("ownerDetails"));
  const baseUrl = process.env.REACT_APP_BASE_URL;
  async function hello() {
    if (dimensions?.latitude && dimensions?.longitude) {
      if (Object.keys(Details).length > 0) {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/checkdistance/${Details.branchId}/${Details.departmentId}/${dimensions?.latitude}/${dimensions?.longitude}`,
            { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
          );
          if (res.data.status == "success") {
            navigating("/language");
            localStorage.setItem(
              "ownerDetails",
              JSON.stringify({
                adminId,
                branchId,
                departmentId,
                lats: dimensions?.latitude,
                long: dimensions?.longitude,
              })
            );
          } else {
            alert(res.data.message);
          }
        } catch (err) {
          alert("Please check your internet connection");
        }
      } else {
        alert("Session expired! please scan the Qr Code");
      }
    } else {
      alert("Please Allow Location (or) reload the Page");
    }
  }

  const geo = () => {
    if (navigator.geolocation) {
      // Request location permission
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Success callback
          dimensions.latitude = position.coords.latitude;
          dimensions.longitude = position.coords.longitude;
          setDimensions({
            ...dimensions,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          // Error callback
          console.error("Error getting location:", error);
        }
      );
    } else {
      // Geolocation not supported by the browser
      alert("Geolocation is not supported by this browser.");
      // You might want to inform the user that their browser doesn't support geolocation
    }
  };

  useEffect(() => {
    const { innerWidth: width } = window;
    if (width >= 767) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}api/getScreenSaver/${Details.branchId}`,
          {
            headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
          }
        )
        .then((res) => {
          setImg(res.data.image);
          setLoading(false);
          localStorage.setItem("image", res.data.image);
          if (res.data.image !== "") {
            const imageUrl = localStorage.getItem("image");
            const fullUrl = `${baseUrl}Screensaver/${imageUrl}`;
            document.getElementsByClassName(
              "homescreen"
            )[0].style.backgroundImage = `url("${fullUrl}")`;
          } else {
            document.getElementsByClassName(
              "homescreen"
            )[0].style.backgroundImage = `url(${homeScreen})`;
          }
        })
        .catch((err) => {
          alert(err);
          setLoading(false);
          document.getElementsByClassName(
            "homescreen"
          )[0].style.backgroundImage = `url(${homeScreen})`;
        });
      setLoading(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}api/getmobileScreenSaver/${Details.branchId}`,
          {
            headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
          }
        )
        .then((res) => {
          setImg(res.data.image);
          setLoading(false);
          if (res.data.image !== "") {
            const imageUrl = res.data.image;
            const fullUrl = `${baseUrl}MobileScreensaver/${imageUrl}`;
            document.getElementsByClassName(
              "homescreen"
            )[0].style.backgroundImage = `url("${fullUrl}")`;
          } else {
            document.getElementsByClassName(
              "homescreen"
            )[0].style.backgroundImage = `url(${homeScreen})`;
          }
        })
        .catch((err) => {
          alert(err);
          setLoading(false);
          document.getElementsByClassName(
            "homescreen"
          )[0].style.backgroundImage = `url(${homeScreen})`;
        });
      setLoading(false);
    }
    geo();
  }, []);

  return (
    <React.Fragment>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
          }),
          content: (base) => ({
            ...base,
            transform: "translate(-50%, -50%)",
          }),
        }}
      >
        <div className="homescreen " onClick={hello}>
          <div className="tap">
            <Lottie
              options={defaultOptions}
              height={150}
              width={150}
              isClickToPauseDisabled={true}
            />
            <p className="tap_text text-center">TAP HERE</p>
          </div>
        </div>
      </LoadingOverlay>
    </React.Fragment>
  );
}

export default Home;
