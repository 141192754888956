import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { Spinner } from "react-bootstrap";
import "../../adminsStyles/superdashboard.css";
import LoadingOverlay from "react-loading-overlay";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";

export default function Superdashboard() {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/adminscount`, {
        headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        setLoading(false);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e.messege);
      });
  }, []);

  const data = [{ name: "Admins", value: count }];

  return (
    <div>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1100,
          }),
          content: (base) => ({
            ...base,
            transform: "translate(-50%, -50%)",
          }),
        }}
      >
        <div className="card db-sty mx-2">
          <div className=" card-body">
            <h5 className="sd-title">Dashboard</h5>
            <div className="d-flex justify-content-center align-items-center">
              <div className="col-md-6 ">
                <div className="card admin-card count">
                  <div className=" ">
                    <h1 className="Admins-Count">{count}</h1>
                  </div>
                  <div>
                    <p className="count_no">No.of admins</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                <BarChart
                  width={400}
                  height={300}
                  data={data}
                  barSize={150}
                  fill="#8EE41E"
                >
                  <XAxis
                    dataKey="name"
                    scale="point"
                    padding={{ left: 5, right: 5 }}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" />
                </BarChart>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}
