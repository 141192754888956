import React from "react";
import dashbord from "../../allSVGS/dashboard.svg";
import hospital from "../../allSVGS/hospital.svg";
import logout from "../../allSVGS/logout.svg";
import "./sidebar.css";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Sidebar({ open }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to Logout?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#000000",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate("/");
      }
    });
  };

  return (
    <div className="col-md-12 sidenavAlign">
      <div className="col-md-3 navi"></div>
      <div className="col-md-10 infoi p-0">
        <ul className=" nav nav-pills flex-sm-column flex-row flex-nowrap  align-items-left px-0  py-4">
          <li className="listType">
            <NavLink
              to={"/SuperAdminMain/Superdashboard"}
              className={({ isActive }) => {
                return isActive ? "link-active" : "normalLink";
              }}
              title=""
            >
              <div className="d-flex align-items-center">
                <div className="svgIcon">
                  <img alt="dashboard icon" src={dashbord} />
                </div>
                <div className="navText ms-4">Dashboard</div>
              </div>
            </NavLink>
          </li>
          <li className="listType">
            <NavLink
              to={"/SuperAdminMain/SuperHospital"}
              className={({ isActive }) => {
                return isActive ? "link-active" : "normalLink";
              }}
              title=""
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-original-title="Home"
            >
              <div className="d-flex align-items-center">
                <div className="svgIcon">
                  <img alt="hospitalicon" src={hospital} />
                </div>
                <div className=" navText ms-4">Centers</div>
              </div>
            </NavLink>
          </li>
          <li className="listType" onClick={handleLogout}>
            <div className="d-flex align-items-center">
              <div className="svgIcon">
                <img alt="logouticon" className="icon-menu" src={logout} />
              </div>
              <div className=" navText ms-4">Logout</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
