import { Outlet, useNavigate } from "react-router-dom";
import Header from "../Header/header";
import Sidebar from "../SideNav/sidebar";
import { useEffect } from "react";

const SuperAdminMain = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="userdetails container">
        <div className="py-2">
          <Header />
        </div>
        <div className="row mt-4">
          <div className="col-md-2">
            <Sidebar />
          </div>
          <div className="col-md-9">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdminMain;
