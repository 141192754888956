import React, { useState, useEffect } from "react";
import "./userdetails.css";
import image from "../allSVGS/image.gif";
import Localstorage from "../utils/Localstorage";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { User } from "../../Redux/Actioncreator";
import useLogout from "../utils/Idle";
import LoadingOverlay from "react-loading-overlay";

export default function Userdetails() {
  const [isTimeout, setIsTimeout] = useState(false);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const details = useSelector((state1) => state1);

  const dispatch = useDispatch();
  let Details = JSON.parse(localStorage.getItem("ownerDetails"));
  let data = Localstorage();
  let navigator = useNavigate();

  const timer = useLogout(60);
  if (timer === 0) {
    navigator(
      `/home/?adminId=${Details.adminId}&branchId=${Details.branchId}&departmentId=${Details.departmentId}&lats=${Details.lats}&long=${Details.long}`
    );
  }

  const Back = () => navigator("/language");

  const onSubmit = async (data, event) => {
    dispatch(User(data.user_name, data.email, data.phone_number));
    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        name: data.user_name,
        email: data.email,
        number: data.phone_number,
      })
    );
    // let response = await Questionsapi();
    // let requiredResponse = response.filter((value) => value.question_type === 1);
    localStorage.removeItem("questionsData");
    navigator("/question1");
  };

  return (
    <>
      
        <div className=" userdetails container-fluid">
          <div className="row margin_align align-items-center">
            <div>
              <h3 className="deparment-title text-center p-3">
                {" "}
                {data.Department}{" "}
              </h3>
            </div>
          </div>
          <div className="offset-lg-2 col-lg-8 mt-4 surface_align ">
            <div className="card_respo surface_cardtop card_width">
              <div className="card surface_card card1">
                <div className="card surface_card card2">
                  <div className="card  surface_card card3">
                    <div className="header mt-4 surface_header">
                      <h4 className="header text-center">{data.Details}</h4>
                    </div>
                    <br />
                    <div className="body surface-body">
                      <form autoComplete="off">
                        <div className="row offset-sm-1 ">
                          <div className="col-md-4 col-xl-3 col-lg-4 ">
                            <label>{data.ContactNumber} </label>
                          </div>
                          <div className="col-md-7 col-xl-7 col-lg-7">
                            <div className="row">
                              <div className="col-lg-3 col-xl-2 col-3 input_pad">
                                <input
                                  type="text"
                                  className="input_brdr"
                                  value="+971"
                                  readOnly
                                />
                              </div>
                              <div className="col-lg-9 col-xl-10 col-9 input_space">
                                <input
                                  type="text"
                                  className="input_brdr"
                                  defaultValue={
                                    details.phone_number
                                      ? details.phone_number
                                      : ""
                                  }
                                  {...register("phone_number", {
                                    required: true,
                                    pattern: /^\d{9,10}$/,
                                  })}
                                />

                                {errors.phone_number &&
                                  errors.phone_number.type === "required" && (
                                    <p className="error mt-0">{data.empty}</p>
                                  )}
                                {errors.phone_number &&
                                  errors.phone_number.type === "pattern" && (
                                    <p className="error mt-0">{data.number}</p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row offset-sm-1 surface_input">
                          <div className="col-md-4 col-xl-3 col-lg-4">
                            <label>
                              {data.FullName}: <small>({data.optional}) </small>
                            </label>
                          </div>
                          <div className="col-md-7 col-xl-7 col-lg-7">
                            {/* <input type="text" defaultValue={details.first_name?details.first_name:''} {...register("user_name", {pattern: /^[\u0621-\u064A\u0660-\u0669a-zA-Z][\u0621-\u064A\u0660-\u0669a-zA-Z+ ]+[u0621-\u064A\u0660-\u0669a-zA-Z]+$/})} className='input_brdr' />*/}
                            <input
                              type="text"
                              defaultValue={
                                details.first_name ? details.first_name : ""
                              }
                              {...register("user_name", {
                                pattern:
                                  /^[\u0621-\u064A\u0660-\u0669a-zA-Z ]+$/,
                              })}
                              className="input_brdr"
                            />
                            {errors.user_name && (
                              <p className="error mt-0">{data.name}</p>
                            )}
                          </div>
                        </div>
                        <br></br>

                        <div className="row offset-sm-1 surface_input">
                          <div className="col-md-4 col-lg-4 col-xl-3">
                            <label>
                              {data.EmailId}: <small>({data.optional})</small>{" "}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="input_brdr"
                              defaultValue={details.email ? details.email : ""}
                              {...register("email", {
                                pattern:
                                  /^[\u0621-\u064A\u0660-\u0669a-zA-Z0-9._-]+@[\u0621-\u064A\u0660-\u0669a-zA-Z0-9.-]+\.([\u0621-\u064A\u0660-\u0669a-zA-Z]{2,5}$)+$/,
                              })}
                            />
                            {errors.email && (
                              <p className="error mt-0">{data.email}</p>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>{" "}
                    <br></br>
                    <div className="row footer footer_direction user_footer ">
                      <div className="col-sm-6 col-6  rightText">
                        <span onClick={Back}>{data.back}</span>
                      </div>

                      <div className="col-sm-6 col-6 leftText">
                        <span onClick={handleSubmit(onSubmit)}>
                          {data.next}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
    </>
  );
}
