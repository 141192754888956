import React, { useState, useEffect } from "react";
import "./Quentionscreen.css";
import Localstorage from "../utils/Localstorage";
import Sad from "../allSVGS/Sad.svg";
import Fair from "../allSVGS/Fair.svg";
import Happy from "../allSVGS/Happy.svg";
import { useNavigate } from "react-router-dom";
import Questionsapi from "../../APIS/QuestionsAPI";
import useLogout from "../utils/Idle";
import axios from "axios";
import Answersposting from "../../APIS/Answersposting";
import LoadingOverlay from "react-loading-overlay";

function QuestionscreenLatest() {
  const [questions, setQuestions] = useState([]);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(true);
  const [handleReview, setHandleReview] = useState({});
  // let Details = JSON.parse(localStorage.getItem("ownerDetails"));
  let selectedlanguage = localStorage.getItem("selectedlanguage");
  let myStyle;
  if (selectedlanguage == "English") {
    myStyle = { whiteSpace: "nowrap" };
  } else {
    myStyle = { whiteSpace: "pre-wrap" };
  }

  let data = Localstorage();

  let navigator = useNavigate();
  const Back = () => navigator("/user");

  const timer = useLogout(60);
  let Details = JSON.parse(localStorage.getItem("ownerDetails"));
  if (timer === 0) {
    navigator(
      `/home/?adminId=${Details.adminId}&branchId=${Details.branchId}&departmentId=${Details.departmentId}&lats=${Details.lats}&long=${Details.long}`
    );
  }
  const { fetchQuestions } = Questionsapi();

  useEffect(() => {
    async function fetchData() {
      let response = await fetchQuestions();
      if (response && response.length !== 0) {
        setLoading(false);
        let requiredResponse = response.filter(
          (value) => value.question_type === 1 && value.question !== ""
        );
        setQuestions(requiredResponse);
      } else {
        setLoading(false);
        setQuestions([]);
      }
    }
    fetchData();
  }, []);

  
  // if (check1.phone_number==='')
  // {
  //   navigator('/user')
  // }

  const onNext = () => {
    if (Object.keys(handleReview).length >= questions.length) {
      localStorage.setItem("questionsData", JSON.stringify(handleReview));
      navigator("/question2");
    } else {
      alert("please answer all questions");
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    let Details = JSON.parse(localStorage.getItem("ownerDetails"));
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/checkdistance/${Details.branchId}/${Details.departmentId}/${Details.lats}/${Details.long}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then(async (res) => {
        if (res.data.status == "success") {
          if (Object.keys(handleReview).length >= questions.length) {
            localStorage.setItem("questionsData", JSON.stringify(handleReview));
            let response = await Answersposting();
            if (response) {
              navigator("/thankyou");
            }
          } else {
            alert("please answer all questions");
          }
        } else {
          alert(res.data.message);
        }
        // navigator("/language")
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 404") {
          localStorage.clear();
          alert("Department is Deleted");
          navigator(
            `/home/?adminId=${Details.adminId}&branchId=${Details.branchId}&departmentId=${Details.departmentId}&lats=${Details.lats}&long=${Details.long}`
          );
        } else {
          alert("please check your internet connection");
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    const data = localStorage.getItem("questionsData");
    if (data !== null) {
      setHandleReview(JSON.parse(data));
    }
  }, []);

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
          }),
          content: (base) => ({
            ...base,
            transform: "translate(-50%, -50%)",
          }),
        }}
      >
        <div className="row questions container-fluid">
          <div className="offset-lg-1 col-lg-10 Qs_scroll">
            <div className="offset-lg-6 mt-4">
              <h3 className=""> {data.Department} </h3>
            </div>
            <div className="card_responsive surface_cardtop">
              <div className="card surface_card card1">
                <div className="card surface_card card2">
                  <div className="card surface_card card3">
                    <div className="body rating_screen m-4">
                      {questions.length > 0 ? (
                        questions.map((values, index) => {
                          let indexvalue = index + 1;
                          // let ques;
                          // if (index === 0) {
                          //   ques = questionval1;
                          // } else if (index === 1) {
                          //   ques = questionval2;
                          // } else {
                          //   ques = questionval3;
                          // }

                          return (
                            <span key={values.q_id}>
                              <div>
                                <p className="question_size1">
                                  {indexvalue}.{values.question}
                                </p>
                                <div className="emoji_review">
                                  <div
                                    className="sad position_align"
                                    onClick={(e) => {
                                      setHandleReview({
                                        ...handleReview,
                                        [values.q_id]: "sad",
                                      });
                                    }}
                                  >
                                    <label>
                                      <input
                                        type="radio"
                                        id="sad"
                                        name="checking"
                                        value="sad"
                                        onChange={(e) =>
                                          setValue({
                                            ...value,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                      <img
                                        className={`emoji ${
                                          handleReview[values.q_id] &&
                                          handleReview[values.q_id] === "sad"
                                            ? "newemoji"
                                            : ""
                                        }`}
                                        src={Sad}
                                        alt="Sad"
                                      />
                                      <span
                                        className={`data ${
                                          handleReview[values.q_id] &&
                                          handleReview[values.q_id] === "sad"
                                            ? "newdata"
                                            : ""
                                        }`}
                                      ></span>
                                    </label>
                                    <div className="sad_emoji"> {data.sad}</div>
                                  </div>
                                  <div className="fair position_align">
                                    <label>
                                      <input
                                        type="radio"
                                        id="neutral"
                                        name="checking"
                                        value="neutral"
                                        onChange={(e) =>
                                          setValue({
                                            ...value,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                      <img
                                        className={`emoji ${
                                          handleReview[values.q_id] &&
                                          handleReview[values.q_id] ===
                                            "neutral"
                                            ? "newemoji"
                                            : ""
                                        }`}
                                        src={Fair}
                                        alt="Fair"
                                        onClick={(e) => {
                                          setHandleReview({
                                            ...handleReview,
                                            [values.q_id]: "neutral",
                                          });
                                        }}
                                      />
                                      <span
                                        className={`data ${
                                          handleReview[values.q_id] &&
                                          handleReview[values.q_id] ===
                                            "neutral"
                                            ? "newdata"
                                            : ""
                                        }`}
                                      ></span>
                                    </label>
                                    <div className="fair_emoji">
                                      {data.fair}
                                    </div>
                                  </div>

                                  <div className="happy position_align">
                                    <label>
                                      <input
                                        type="radio"
                                        id="happy"
                                        name="checking"
                                        value="happy"
                                        onChange={(e) =>
                                          setValue({
                                            ...value,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                      <img
                                        className={`emoji ${
                                          handleReview[values.q_id] &&
                                          handleReview[values.q_id] === "happy"
                                            ? "newemoji"
                                            : ""
                                        }`}
                                        src={Happy}
                                        alt="Happy"
                                        onClick={(e) => {
                                          setHandleReview({
                                            ...handleReview,
                                            [values.q_id]: "happy",
                                          });
                                        }}
                                      />
                                      <span
                                        className={`data ${
                                          handleReview[values.q_id] &&
                                          handleReview[values.q_id] === "happy"
                                            ? "newdata"
                                            : ""
                                        }`}
                                      ></span>
                                    </label>
                                    <div className="happy_emoji">
                                      {data.happy}
                                    </div>
                                  </div>
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </span>
                          );
                        })
                      ) : (
                        <p>No questions to display</p>
                      )}

                      <div
                        className={`row footer footer_direction direction_footer pb-2 pt-4 ${
                          selectedlanguage == "Arabic" ? "arabic_ques" : ""
                        }`}
                      >
                        <div className="col-sm-6 col-6 col-lg-4">
                          <span onClick={Back}>{data.back}</span>
                        </div>

                        <div className="col-sm-6 col-6 col-lg-8  ">
                          <span
                            onClick={
                              Object.keys(handleReview).length >=
                                questions.length && onNext
                            }
                            className={
                              Object.keys(handleReview).length >=
                              questions.length
                                ? "back_img"
                                : ""
                            }
                          >
                            {data.next1}
                          </span>
                          <div>
                            <span
                              onClick={
                                Object.keys(handleReview).length >=
                                  questions.length && onSubmit
                              }
                              className={
                                Object.keys(handleReview).length >=
                                questions.length
                                  ? "back_img "
                                  : ""
                              }
                            >
                              {data.submit1}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default QuestionscreenLatest;
