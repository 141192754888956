import React, { useState, useEffect } from "react";
import "./Language.css";
import image from "../allSVGS/image.gif";
import Adminloginmodal from "../adminloginmodal/Adminloginmodal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Reset } from "../../Redux/Actioncreator";
import useLogout from "../utils/Idle";
import LoadingOverlay from "react-loading-overlay";

function Language() {
  let navigator = useNavigate();
  const [department, setDepartment] = useState("");
  const [loading, setLoading] = useState(true);
  const [engCount, setEngCount] = useState("");
  const [arabicCount, setArabicCount] = useState("");

  let dispatch = useDispatch();

  let Details = JSON.parse(localStorage.getItem("ownerDetails"));

  const timer = useLogout(60);
  if (timer === 0) {
    navigator(
      `/home/?adminId=${Details.adminId}&branchId=${Details.branchId}&departmentId=${Details.departmentId}&lats=${Details.lats}&long=${Details.long}`
    );
  }
  useEffect(() => {
    let Details = JSON.parse(localStorage.getItem("ownerDetails"));
    let DiD = Details.departmentId;
    if (DiD == null) {
      DiD = 1;
    }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/departmentsbyid/${DiD}`, {
        headers: {
          Authorization: process.env.REACT_APP_AUTH_KEY,
        },
      })
      .then((res) => {
        let responseDepartment = res.data.data.department[0];
        localStorage.setItem(
          "selecteddepartment",
          JSON.stringify(responseDepartment)
        );
        setDepartment(responseDepartment.department_english);
        setEngCount(res.data.data.english_question_count);
        setArabicCount(res.data.data.arabic_question_count);
        setLoading(false);
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 404") {
          localStorage.clear();
          alert("Department is Deleted");
          navigator(
            `/home/?adminId=${Details.adminId}&branchId=${Details.branchId}&departmentId=${Details.departmentId}&lats=${Details.lats}&long=${Details.long}`
          );
        }
        setLoading(false);
      });
    dispatch(Reset());
  }, []);

  const changeLanguage = (lang) => {
    localStorage.setItem("selectedlanguage", lang);
    navigator("/user");
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
          }),
          content: (base) => ({
            ...base,
            transform: "translate(-50%, -50%)",
          }),
        }}
      >
        <div className=" container-fluid first space_between">
          <div className="row">
            {/* <div className="d-md-flex  align-items-center">
          <div className="image col-md-5">
            <img className="image1" src={image} alt="icon" />
          </div>
          <div className="department">
            {department && (
              <button className="department1 text-center btn btn-lg lang_btn1">
                {" "}
                {department}{" "}
              </button>
            )}
          </div>
        </div> */}
            <div className="department ">
              {department && (
                <button className="department1 text-center btn btn-lg lang_btn1">
                  {" "}
                  {department}{" "}
                </button>
              )}
            </div>
          </div>
          <div className="language language_margin offset-md-3 col-md-6">
            <h4 className="text-center">Choose the language to </h4>
            <h4 className="text-center">proceed with your feedback</h4>
            {engCount === "0" && arabicCount === "0" ? (
              <h4 className="text-center">No Questions</h4>
            ) : (
              <></>
            )}
            <div className="row mt-4">
              <div className="col-6 col-md-6 text-center ">
                <button
                  onClick={() => changeLanguage("English")}
                  className="btn btn-lg lang_btn"
                  disabled={engCount === "0"}
                >
                  ENGLISH
                </button>
              </div>
              <div className="col-6 col-md-6 text-center">
                <button
                  onClick={() => changeLanguage("Arabic")}
                  className="btn  btn-lg lang_btn"
                  disabled={arabicCount === "0"}
                >
                  عربي
                </button>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default Language;
